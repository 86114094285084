<template>
  <div class="d-flex flex-column vh-100">
    <absence-approval id="approve-reject-absence" :absence="selectedAbsence" />
    <absence-view id="absence-view" :absence="selectedAbsence" />
    <edit-absence id="edit-absence" :absence="selectedAbsence" />
    <request-holiday id="request-holiday" />
    <sticky-header title="Absences" :subtitle="calendarDateTitle">
      <template #buttons>
        <b-button class="d-none d-md-inline-block ml-2" @click.prevent="calPrev" variant="outline-primary"><fa-icon icon="angle-left"/></b-button>
        <b-button class="d-none d-md-inline-block ml-2" @click.prevent="calToday" variant="outline-primary">Today</b-button>
        <b-button class="d-none d-md-inline-block ml-2" @click.prevent="calNext" variant="outline-primary"><fa-icon icon="angle-right"/></b-button>
        <b-button class="d-none d-md-inline-block ml-4" :to="{ name: 'absences.create' }" variant="outline-primary"><fa-icon class="mr-2" icon="plus" /> Log Absence</b-button>
        <b-button class="ml-2" :to="{ name: 'absences.request' }" variant="primary"><fa-icon class="mr-2" icon="umbrella-beach" /> Request</b-button>
      </template>
    </sticky-header>
    <div class="bg-light border-bottom d-md-none d-flex flex-row justify-content-end px-4 py-2">
      <b-button class="align-self-left" :to="{ name: 'absences.create' }" variant="outline-primary"><fa-icon class="mr-2" icon="plus" /> Sickness</b-button>
      <b-button class="ml-2" @click.prevent="calPrev" variant="outline-primary"><fa-icon icon="angle-left"/></b-button>
      <b-button class="ml-2" @click.prevent="calToday" variant="outline-primary">Today</b-button>
      <b-button class="ml-2" @click.prevent="calNext" variant="outline-primary"><fa-icon icon="angle-right"/></b-button>
    </div>
    <div class="flex-fill p-3">
      <div class="d-flex flex-column mt-5 py-3 justify-content-center" v-if="loading">
        <b-spinner class="align-self-center" variant="primary" />
        <p class="align-self-center mt-1 mb-0">Loading...</p>
      </div>
      <full-calendar ref="calendar" :options="calendarOptions" v-show="!loading" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import get from 'lodash/get'
import map from 'lodash/map'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import AbsenceApproval from './Modals/AbsenceApproval.vue'
import AbsenceView from './Modals/AbsenceView.vue'
import RequestHoliday from './Modals/RequestHoliday.vue'
import StickyHeader from '../components/StickyHeader.vue'
import EditAbsence from './Modals/EditAbsence.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: { AbsenceApproval, AbsenceView, EditAbsence, FullCalendar, RequestHoliday, StickyHeader },
  computed: {
    ...mapState('absences', ['absences', 'loading']),
    ...mapState('auth', ['user']),
    selectedAbsence() {
      const data = get(this.absences, 'data');
      return data && data.find(a => parseInt(this.selectedAbsenceId) === a.id) || {};
    }
  },
  async mounted() {
    if (this.$route.meta.modalId) {
      this.$bvModal.show(this.$route.meta.modalId)
    }

    this.$root.$on('bv::modal::hide', () => {
      this.load(this.date);
    });

    if (this.$route.query.date) {
      this.calRouteQuery()
    } else {
      this.calToday()
    }
  },
  data() {
    return {
      date: moment(),
      selectedAbsenceId: null,
      calendarDateTitle: 'Loading',
      calendarOptions: {
        // Plugins, Themes and View
        plugins: [ bootstrapPlugin, dayGridPlugin ],
        initialView: 'dayGridMonth',
        themeSystem: 'bootstrap',
        headerToolbar: false,
        weekends: true,
        height: "100%",

        // Events and Vue Events
        eventClick: this.calEventClicked,
        events: [],

        columnFormat: {
            month: 'ddd',
            week: 'ddd d/M',
            day: 'dddd d/M'
        },
      }
    }
  },
  methods: {
    ...mapActions('absences', ['fetch']),
    calEventClicked(absence) {
      this.selectedAbsenceId = get(absence, 'event.id');

      if (this.selectedAbsence && this.selectedAbsence.approved_at === null) {
        this.$bvModal.show('approve-reject-absence');
      } else {
        this.$bvModal.show('absence-view');
      }
    },
    calNext() {
      this.$refs.calendar.getApi().incrementDate({ month: 1 });
      this.$refs.calendar.getApi().render();
      this.calendarDateTitle = moment(this.$refs.calendar.getApi().getDate()).format('MMMM YYYY');
      this.load();
    },
    calPrev() {
      this.$refs.calendar.getApi().incrementDate({ month: -1 });
      this.$refs.calendar.getApi().render();
      this.calendarDateTitle = moment(this.$refs.calendar.getApi().getDate()).format('MMMM YYYY');
      this.load();
    },
    calToday() {
      this.$refs.calendar.getApi().today();
      this.$refs.calendar.getApi().render();
      this.calendarDateTitle = moment(this.$refs.calendar.getApi().getDate()).format('MMMM YYYY');
      this.load();
    },
    calRouteQuery() {
      this.date = moment(this.$route.query.date ?? moment());
      this.calendarDateTitle = this.date.format('MMMM YYYY');
      this.$refs.calendar.getApi().gotoDate(this.date.toDate());
      this.load();
    },
    async load() {
      const after = moment(this.$refs.calendar.getApi().view.activeStart);
      const before = moment(this.$refs.calendar.getApi().view.activeEnd);
      const userId = this.user.type !== 'staff' ? this.user.id : null;

      await this.fetch({ after, before, userId });
    }
  },
  watch: {
    async '$route'(newValue, value) {
      if (!value.meta.modalId && newValue.meta.modalId) {
        return this.$bvModal.show(newValue.meta.modalId);
      }

      return this.$bvModal.hide(value.meta.modalId);
    },
    absences({ data }) {
      this.calendarOptions = {
        ...this.calendarOptions,
        events: map(data, ab => ({
          id: ab.id,
          title: `${get(ab, 'absentable.name')}'s ${ab.type === 'holiday' ? 'Holiday' : 'Absence'}`,
          start: ab.start_date,
          end: moment(ab.end_date).add(1, 'days').toDate(),
          allDay: true,

          // Display
          backgroundColor: ab.approved_at ? '#1A1A1A' : '#EEEEEE',
          borderColor: ab.approved_at ? '#1A1A1A' : '#EEEEEE',
          textColor: ab.approved_at ? '#FFFFFF' : '#1A1A1A',
        })),
      };
    }
  }
}
</script>

<style scoped>
.fc-button {
  border-radius: 0;
}

.fc-button-primary {
  background: #1A1A1A;
}
</style>
